import React, { useEffect, useState } from 'react';
import preloader from 'assets/preloader.gif';

export * from './LoaderContext';

export const Loader = () => {
  const [windowLoaded, setWindowLoaded] = useState(false);
  useEffect(() => {
    const fn = () => setWindowLoaded(true);
    window.addEventListener('load', fn);
    return () => window.removeEventListener('load', fn);
  }, []);
  if (!windowLoaded) return null;
  return (
    <>
      <div className="preloader">
        <div className="layer"></div>
        <div className="layer"></div>
        <div className="layer"></div>
        <div className="layer"></div>
        <div className="inner" data-tilt data-tilt-perspective="2000">
          <figure className="fadeInUp animated">
            <img src={preloader} alt="preloader gif" />
          </figure>
          <span className="typewriter" id="typewriter"></span>
        </div>
      </div>
      <TransitionOverlay />
    </>
  );
};

export const TransitionOverlay = () => (
  <div className="transition-overlay">
    <div className="layer"></div>
    <div className="layer"></div>
    <div className="layer"></div>
    <div className="layer"></div>
  </div>
);
