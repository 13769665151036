import React from 'react';

const staticData = {
  email: 'office@synelution.com'
};

export const staticDataContext = React.createContext(staticData);

export const StaticDataProvider = ({ children }) => (
  <staticDataContext.Provider value={staticData}>
    {children}
  </staticDataContext.Provider>
);
