import React, { useEffect, useContext } from 'react';
// import ReactGA from 'react-ga';
import { CookieContext } from 'router/cookie-notice/CookieContext';

// ReactGA.initialize('UA-147447473-2');
export const withGA = (WrappedComponent, options = {}) => {
  // const trackPage = (page) => {
  //   ReactGA.set({
  //     page,
  //     ...options,
  //   });
  //   // ReactGA.pageview(page);
  // };

  const HOC = (props) => {
    const { form } = useContext(CookieContext);

    useEffect(() => {
      if (!form.googleAnalytics.value) return;
      // trackPage(props.location.pathname);
    }, [props.location.pathname, form.googleAnalytics.value]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
